<template>
  <div>
    <b-row class="match-height"      
    >
      <b-col
        md="4"
        v-for="(item,index) in order_stats"
        :key="item[index]"
      >
        <statistic-card-vertical
          :icon="getIcon()"
          :statistic="item"
          :statistic-title="index.replace('_',' ')"
          :color="getColor()"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import axios from 'axios'

export default {
components:{
     BRow, 
     BCol,
    StatisticCardVertical
},
data(){
    return {
        order_stats : {},
        icons:['TruckIcon','AwardIcon','ShoppingBagIcon','MessageSquareIcon','EyeIcon'],
        colors:['info','warning','danger','primary','success']
    }
},
created(){
    axios
      .get(process.env.VUE_APP_API+'/order-statistics')
      .then(response => {
          this.order_stats = response.data
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
      })
  },
  methods:{
      getIcon(){
          return String(this.icons.splice(Math.floor(Math.random()*this.icons.length), 1))

  
      },
      getColor(){
            return String(this.colors.splice(Math.floor(Math.random()*this.colors.length), 1))
      }
  }
}
</script>

<style>

</style>