<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <welcome :data="welcome" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <statistics :statisticsItems="statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col md="6">
        <order-stats/>
      </b-col>

      <b-col md="6">
          <transaction-chart />
        </b-col>

      <!-- <b-col md="6">
          <order-chart />
        </b-col> -->
    </b-row>

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import Welcome from './Welcome.vue'
import Statistics from './Statistics.vue'
import TransactionChart from './TransactionChart'
import OrderChart from './OrderChart'
import OrderStats from './OrderStats'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    Welcome,
    Statistics,
    TransactionChart,
    OrderChart,
    OrderStats
  },
  data() {
    return {
      welcome:{
        title:'Auto Parts Hub'
        },
      statisticsCount : {},
      statisticsItems: [
          {
            icon: 'ShoppingCartIcon',
            color: 'light-primary',
            title:'',
            subtitle: 'Orders',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'TruckIcon',
            color: 'light-info',
            title:34,
            subtitle: 'Procssing Orders',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-danger',
            title:  434,
            subtitle: 'Products',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'ShoppingBagIcon',
            color: 'light-warning',
            title: 34,
            subtitle: 'Purchase Orders',
            customClass: 'mb-2 mb-sm-0',
          },
        ],
    }
    
  },
  created(){
    this.getStatisticsData()
  },
  methods: {
    kFormatter,
    getStatisticsData(){
      try {
        axios.get(process.env.VUE_APP_API+'/get-statistics')
        .then(res =>{
          this.statisticsCount = res.data
          this.assignTitleToStatistics()
        })
      } catch (error) {
        console.log(error)
      }
    },
    assignTitleToStatistics(){
      for (let i = 0; i < this.statisticsItems.length; i++) {
        if(this.statisticsItems[i].subtitle==='Orders'){
          this.statisticsItems[i].title = this.statisticsCount.orders_count
        }
        if(this.statisticsItems[i].subtitle==='Procssing Orders'){
          this.statisticsItems[i].title = this.statisticsCount.shipped_orders_count
        }
        if(this.statisticsItems[i].subtitle==='Products'){
          this.statisticsItems[i].title = this.statisticsCount.products_count
        }
        if(this.statisticsItems[i].subtitle==='Purchase Orders'){
          this.statisticsItems[i].title = this.statisticsCount.purchase_orders_count
        }
    }
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
