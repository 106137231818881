<template>
  <b-card title="Orders">
    <!-- chart -->
    <order-chart-donut-component
      :height="275"
      :data="doughnutChart.data"
      :options="doughnutChart.options"
      :status="order_status"
      class="mb-3"
    />
    <div
      v-for="(order,index) in order_status"
      :key="order[index]"
      :class="index < order_status.length-1 ? 'mb-1':''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CircleIcon"
          size="16"
          class="text-primary"
        />

        <span class="font-weight-bold ml-75 mr-25">{{ index.replace('_',' ')}}</span>
        <span>- {{ order }}</span>
      </div>
      <!-- <div>
        <span>{{ stock.upDown }}%</span>
        <feather-icon
          :icon="stock.upDown > 0 ? 'ArrowUpIcon':'ArrowDownIcon'"
          :class="stock.upDown > 0 ? 'text-success':'text-danger'"
        />
      </div> -->
    </div>

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import OrderChartDonutComponent from './OrderChartDonutComponent.vue'
import axios from 'axios'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    OrderChartDonutComponent,
    BCard,
  },
  data() {
    return {
      order_status : {},
      chartColors : {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      },
      doughnutChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          cutoutPercentage: 60,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label:'212'
            },       
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          datasets: [
            {
              labels: ['Shipped', 'Cancelled', 'Pending'],
              data: [40, 5, 24],
              backgroundColor: ['#28dac6', '#2c9aff', '#ffe802'],
              borderWidth: 0,
              pointStyle: 'rectRounded',
            },
          ],
        },
      },
    }
  },
  created(){
    axios
      .get(process.env.VUE_APP_API+'/order-statistics')
      .then(response => {
  
          this.order_status = response.data
          //this.assignData()
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
      })
  },
  methods:{
    assignData(){

      this.doughnutChart.data.datasets[0].labels.push(Object.keys(this.order_status))
      this.doughnutChart.data.datasets[0].data.push(Object.values(this.order_status))

      
    }
  }
}
</script>
